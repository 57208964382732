import { log } from '@guardian/libs';
const supportsPerformanceAPI = () => typeof window !== 'undefined' &&
    typeof window.performance !== 'undefined' &&
    typeof window.performance.mark === 'function';
// Events will be logged using the performance API for all slots, but only these slots will be tracked as commercial metrics and sent to the data lake
const trackedSlots = ['top-above-nav', 'inline1', 'inline2'];
// marks that we want to save as commercial metrics
const slotMarks = ['slotReady', 'adRenderStart', 'adOnPage'];
// measures that we want to save as commercial metrics
const slotMeasures = [
    'adRender',
    'defineSlot',
    'prepareSlot',
    'prebid',
    'fetchAd',
];
const pageMarks = ['commercialStart', 'commercialModulesLoaded'];
// measures that we want to save as commercial metrics
const pageMeasures = ['commercialBoot', 'googletagInit'];
// all marks, including the measure start and end marks
const allSlotMarks = [
    ...slotMarks,
    ...slotMeasures.map((measure) => `${measure}Start`),
    ...slotMeasures.map((measure) => `${measure}End`),
];
const externalMarks = [
    'cmp-init',
    'cmp-ui-displayed',
    'cmp-got-consent',
];
const shouldSave = (name) => {
    let [origin, type] = name.split('_');
    if (!type) {
        type = origin;
        origin = 'page';
    }
    const shouldSaveMark = (trackedSlots.includes(origin) &&
        slotMarks.includes(type)) ||
        (origin === 'page' && pageMarks.includes(type));
    const shouldSaveMeasure = (trackedSlots.includes(origin) &&
        slotMeasures.includes(type)) ||
        (origin === 'page' && pageMeasures.includes(type));
    return shouldSaveMark || shouldSaveMeasure;
};
class EventTimer {
    _marks;
    _measures;
    properties;
    /**
     * Initialise the EventTimer class on page.
     * Returns the singleton instance of the EventTimer class and binds
     * to window.guardian.commercialTimer. If it's been previously
     * initialised and bound it returns the original instance
     * Note: We save to window.guardian.commercialTimer because
     * different bundles (DCR / DCP) can use commercial core, and we want
     * all timer events saved to a single instance per-page
     * @returns {EventTimer} Instance of EventTimer
     */
    static init() {
        return (window.guardian.commercialTimer ||= new EventTimer());
    }
    /**
     * Just a helper method to access the singleton instance of EventTimer.
     * Typical use case is EventTimer.get().trigger
     */
    static get() {
        return this.init();
    }
    /**
     * These are marks that are not triggered by commercial but we are interested in
     * tracking their performance. For example, CMP-related events.
     **/
    get _externalMarks() {
        if (!supportsPerformanceAPI()) {
            return new Map();
        }
        return externalMarks.reduce((map, mark) => {
            const entries = window.performance.getEntriesByName(mark);
            if (entries.length && entries[0]) {
                map.set(mark, entries[0]);
            }
            return map;
        }, new Map());
    }
    /**
     * Returns all performance marks that should be saved as commercial metrics.
     */
    get marks() {
        return [...this._marks, ...this._externalMarks].map(([name, timer]) => ({
            name,
            ts: timer.startTime,
        }));
    }
    /**
     * Returns all performance measures that should be saved as commercial metrics.
     */
    get measures() {
        return [...this._measures].map(([name, measure]) => ({
            name,
            duration: measure.duration,
        }));
    }
    constructor() {
        this._marks = new Map();
        this._measures = new Map();
        this.properties = {};
        if (window.navigator.connection) {
            this.properties.type = window.navigator.connection.type;
            this.properties.downlink = window.navigator.connection.downlink;
            this.properties.effectiveType =
                window.navigator.connection.effectiveType;
        }
    }
    /**
     * Adds a non timer measurement
     *
     * @param {string} name - the property's name
     * @param value - the property's value
     */
    setProperty(name, value) {
        this.properties[name] = value;
    }
    /**
     * Creates a new performance mark, and if the mark ends with 'End' it will
     * create a performance measure between the start and end marks.
     *
     * Marks can  be triggered multiple times, but we only save the first
     * instance of a mark, as things like ad refreshes can trigger the same mark.
     *
     * More info on the performance API:
     * https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
     * https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
     *
     * @todo more strict typing for eventName and origin
     * @param eventName The short name applied to the mark
     * @param origin - Either 'page' (default) or the name of the slot
     */
    mark(eventName, origin = 'page') {
        let name = eventName;
        if (allSlotMarks.includes(eventName) && origin !== 'page') {
            name = `${origin}_${name}`;
        }
        if (this._marks.get(name) || !supportsPerformanceAPI()) {
            return;
        }
        const mark = window.performance.mark(name);
        if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- browser support is patchy
        typeof mark?.startTime === 'number' &&
            // we only want to save the marks that are related to certain slots or the page
            shouldSave(name)) {
            this._marks.set(name, mark);
        }
        if (name.endsWith('End')) {
            this.measure(name);
        }
    }
    /**
     * Creates a performance measure given the name of the end marks.
     * The start mark is inferred from the end mark.
     *
     * @param endMark - The name of the mark that ends the measure
     **/
    measure(endMark) {
        const startMark = endMark.replace('End', 'Start');
        const measureName = endMark.replace('End', '');
        const startMarkExists = window.performance.getEntriesByName(startMark).length > 0;
        if (startMarkExists) {
            try {
                const measure = window.performance.measure(measureName, startMark, endMark);
                // we only want to save the measures that are related to certain slots or the page
                if (shouldSave(measureName)) {
                    this._measures.set(measureName, measure);
                }
            }
            catch (e) {
                log('commercial', `error measuring ${measureName}`, e);
            }
        }
    }
}
const _ = {
    slotMarks,
    slotMeasures,
    trackedSlots,
};
export { EventTimer, _ };
