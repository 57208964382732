import { buildPageTargeting } from './build-page-targeting';
const consentlessTargetingKeys = [
    'ab',
    'at',
    'bl',
    'bp',
    'br',
    'cc',
    'ct',
    'dcre',
    'edition',
    'k',
    'rc',
    'rp',
    's',
    'se',
    'sens',
    'sh',
    'si',
    'skinsize',
    'su',
    'tn',
    'url',
    'urlkw',
];
const isConsentlessKey = (key) => consentlessTargetingKeys.includes(key);
/**
 * Call buildPageTargeting then filter out the keys that are not needed for
 * consentless targeting.
 *
 * @param  {ConsentState} consentState
 * @param  {boolean} adFree
 * @returns ConsentlessPageTargeting
 */
const buildPageTargetingConsentless = (consentState, adFree) => {
    const consentedPageTargeting = buildPageTargeting({
        adFree,
        consentState,
        clientSideParticipations: {},
    });
    return Object.fromEntries(Object.entries(consentedPageTargeting).filter(([k]) => isConsentlessKey(k)));
};
export { buildPageTargetingConsentless };
