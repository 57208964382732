import { log } from '@guardian/libs';
import { adSizes } from 'core/ad-sizes';
import { createAdSlot } from 'core/create-ad-slot';
import { commercialFeatures } from 'lib/commercial-features';
import { getCurrentBreakpoint } from 'lib/detect/detect-breakpoint';
import { fillDynamicAdSlot } from 'lib/dfp/fill-dynamic-advert-slot';
import { isInEagerPrebidVariant } from 'lib/experiments/eager-prebid-check';
import fastdom from 'lib/fastdom-promise';
import { requestBidsForAds } from 'lib/header-bidding/request-bids';
import { spaceFiller } from 'lib/spacefinder/space-filler';
/**
 * Maximum number of inline ads to display on the page.
 */
const MAX_ADS = 8;
/**
 * Multiplier of screen height that determines the distance from
 * the top of the page that we can start placing ads.
 */
const PAGE_TOP_MULTIPLIER = 1.5;
/**
 * Multiplier of screen height that sets the minimum distance that two ads can be placed.
 */
const AD_SPACE_MULTIPLIER = 2;
let AD_COUNTER = 0;
let WINDOWHEIGHT;
let firstSlot;
let insertedDynamicAds = [];
const getWindowHeight = (doc = document) => {
    if (doc.documentElement.clientHeight) {
        return doc.documentElement.clientHeight;
    }
    return 0; // #? zero, or throw an error?
};
const getSpaceFillerRules = (windowHeight, shouldUpdate = false) => {
    let prevSlot;
    const isEnoughSpaceBetweenSlots = (prevSlot, slot) => Math.abs(slot.top - prevSlot.top) > windowHeight * AD_SPACE_MULTIPLIER;
    const filterSlot = (slot) => {
        if (!prevSlot) {
            prevSlot = slot;
            return !shouldUpdate;
        }
        else if (isEnoughSpaceBetweenSlots(prevSlot, slot)) {
            prevSlot = slot;
            return true;
        }
        return false;
    };
    return {
        bodySelector: '.js-liveblog-body',
        slotSelector: ' > .block',
        fromBottom: shouldUpdate,
        startAt: shouldUpdate ? firstSlot : undefined,
        absoluteMinAbove: shouldUpdate ? 0 : WINDOWHEIGHT * PAGE_TOP_MULTIPLIER,
        minAbove: 0,
        minBelow: 0,
        clearContentMeta: 0,
        selectors: {},
        filter: filterSlot,
    };
};
const getSlotName = (isMobile, slotCounter) => {
    if (isMobile && slotCounter === 0) {
        return 'top-above-nav';
    }
    else if (isMobile) {
        return `inline${slotCounter}`;
    }
    return `inline${slotCounter + 1}`;
};
const insertAdAtPara = (para) => {
    const isMobile = getCurrentBreakpoint() === 'mobile';
    const container = document.createElement('div');
    container.className = `ad-slot-container`;
    const ad = createAdSlot('inline', {
        name: getSlotName(isMobile, AD_COUNTER),
        classes: 'liveblog-inline',
    });
    container.appendChild(ad);
    return fastdom
        .mutate(() => {
        if (para.parentNode) {
            /* ads are inserted after the block on liveblogs */
            para.parentNode.insertBefore(container, para.nextSibling);
        }
    })
        .then(async () => {
        const advert = await fillDynamicAdSlot(ad, false, {
            phablet: [
                adSizes.outstreamDesktop,
                adSizes.outstreamGoogleDesktop,
            ],
            desktop: [
                adSizes.outstreamDesktop,
                adSizes.outstreamGoogleDesktop,
            ],
        });
        if (advert) {
            insertedDynamicAds.push(advert);
        }
    });
};
const insertAds = async (paras) => {
    const fastdomPromises = [];
    for (let i = 0; i < paras.length && AD_COUNTER < MAX_ADS; i += 1) {
        const para = paras[i];
        if (para.parentNode) {
            const result = insertAdAtPara(para);
            fastdomPromises.push(result);
            AD_COUNTER += 1;
        }
    }
    await Promise.all(fastdomPromises);
};
const onUpdate = () => {
    // eslint-disable-next-line no-use-before-define -- circular reference
    stopListening();
    const rules = getSpaceFillerRules(WINDOWHEIGHT, true);
    // eslint-disable-next-line no-use-before-define -- circular reference
    void fill(rules);
};
const startListening = () => {
    document.addEventListener('liveblog:blocks-updated', onUpdate);
};
const stopListening = () => {
    document.removeEventListener('liveblog:blocks-updated', onUpdate);
};
const fill = (rules) => {
    const options = { pass: 'inline1' };
    return spaceFiller
        .fillSpace(rules, insertAds, options)
        .then(() => {
        if (AD_COUNTER < MAX_ADS) {
            const el = document.querySelector(`${rules.bodySelector} > .ad-slot-container`);
            if (el && el.previousSibling instanceof HTMLElement) {
                firstSlot = el.previousSibling;
            }
            else {
                firstSlot = undefined;
            }
            startListening();
        }
        else {
            firstSlot = undefined;
        }
    })
        .then(async () => {
        if (insertedDynamicAds.length && isInEagerPrebidVariant()) {
            await requestBidsForAds(insertedDynamicAds);
        }
        insertedDynamicAds = [];
    });
};
/**
 * Initialise liveblog ad slots
 */
export const init = () => {
    if (!commercialFeatures.liveblogAdverts) {
        return Promise.resolve();
    }
    const isServerSideAdsMode = window.guardian.config.tests?.serverSideLiveblogInlineAdsVariant ===
        'variant';
    if (isServerSideAdsMode) {
        log('commercial', 'Server side inline ads mode. No client-side inline ad slots inserted');
        return Promise.resolve();
    }
    return fastdom
        .measure(() => {
        WINDOWHEIGHT = getWindowHeight();
        return WINDOWHEIGHT;
    })
        .then(getSpaceFillerRules)
        .then(fill);
};
export const _ = { getSlotName };
