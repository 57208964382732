/**
 * Store ad sizes in a way that is compatible with google-tag but also accessible via
 * more semantic `width`/`height` properties and keep things readonly.
 *
 * example:
 * const size = new AdSize([300, 250]);
 *
 * size.width === 300; // true
 * size[0] === 300; // true
 *
 * size.height === 250; // true
 * size[1] === 250; // true
 *
 * size[0] = 200; // throws error
 * size.width = 200; // throws error
 *
 */
class AdSize extends Array {
    [0];
    [1];
    constructor([width, height]) {
        super();
        this[0] = width;
        this[1] = height;
    }
    toString() {
        return this.width === 0 && this.height === 0
            ? 'fluid'
            : `${this.width},${this.height}`;
    }
    toArray() {
        return [this[0], this[1]];
    }
    // The advert size is not reflective of the actual size of the advert.
    // For example, fluid ads and Guardian merch ads are larger than the dimensions
    isProxy() {
        const isOutOfPage = this.width === 1 && this.height === 1;
        const isEmpty = this.width === 2 && this.height === 2;
        const isFluid = this.toString() === 'fluid';
        const isMerch = this.width === 88;
        return isOutOfPage || isEmpty || isFluid || isMerch;
    }
    get width() {
        return this[0];
    }
    get height() {
        return this[1];
    }
}
const createAdSize = (width, height) => {
    return new AdSize([width, height]);
};
const namedStandardAdSizes = {
    billboard: createAdSize(970, 250),
    halfPage: createAdSize(300, 600),
    leaderboard: createAdSize(728, 90),
    mobilesticky: createAdSize(320, 50),
    mpu: createAdSize(300, 250),
    portrait: createAdSize(300, 1050),
    skyscraper: createAdSize(160, 600),
    cascade: createAdSize(940, 230),
    portraitInterstitial: createAdSize(320, 480),
};
const standardAdSizes = {
    '970x250': namedStandardAdSizes.billboard,
    '300x600': namedStandardAdSizes.halfPage,
    '728x90': namedStandardAdSizes.leaderboard,
    '300x250': namedStandardAdSizes.mpu,
    '300x1050': namedStandardAdSizes.portrait,
    '160x600': namedStandardAdSizes.skyscraper,
};
const outstreamSizes = {
    outstreamDesktop: createAdSize(620, 350),
    outstreamGoogleDesktop: createAdSize(550, 310),
    outstreamMobile: createAdSize(300, 197),
};
/**
 * Ad sizes commonly associated with third parties
 */
const proprietaryAdSizes = {
    fluid: createAdSize(0, 0),
    googleCard: createAdSize(300, 274),
    outOfPage: createAdSize(1, 1),
};
/**
 * Ad sizes associated with in-house formats
 */
const guardianProprietaryAdSizes = {
    empty: createAdSize(2, 2),
    fabric: createAdSize(88, 71),
    inlineMerchandising: createAdSize(88, 85),
    merchandising: createAdSize(88, 88),
    merchandisingHigh: createAdSize(88, 87),
    merchandisingHighAdFeature: createAdSize(88, 89),
};
const adSizes = {
    ...namedStandardAdSizes,
    ...standardAdSizes,
    ...outstreamSizes,
    ...proprietaryAdSizes,
    ...guardianProprietaryAdSizes,
};
/**
 * mark: 432b3a46-90c1-4573-90d3-2400b51af8d0
 * Some of these may or may not need to be synced for with the sizes in ./create-ad-slot.ts
 * these were originally from DCR, create-ad-slot.ts ones were in frontend.
 **/
const slotSizeMappings = {
    inline: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.outstreamMobile,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
        ],
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
        ],
    },
    right: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.halfPage,
            adSizes.fluid,
        ],
    },
    'liveblog-right': {
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.halfPage,
            adSizes.fluid,
        ],
    },
    comments: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.outstreamMobile,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
        ],
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
        ],
    },
    'comments-expanded': {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
            adSizes.skyscraper,
            adSizes.halfPage,
        ],
    },
    'top-above-nav': {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.fabric,
            adSizes.outstreamMobile,
            adSizes.mpu,
            adSizes.fluid,
        ],
        tablet: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.fabric,
            adSizes.fluid,
            adSizes.leaderboard,
        ],
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.leaderboard,
            createAdSize(940, 230),
            createAdSize(900, 250),
            adSizes.billboard,
            adSizes.fabric,
            adSizes.fluid,
        ],
    },
    'fronts-banner': {
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.billboard,
            adSizes.fabric,
            adSizes.fluid,
        ],
    },
    mostpop: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.fluid,
        ],
        phablet: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.outstreamMobile,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.halfPage,
            adSizes.fluid,
        ],
        tablet: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.halfPage,
            adSizes.leaderboard,
            adSizes.fluid,
        ],
        desktop: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.mpu,
            adSizes.googleCard,
            adSizes.halfPage,
            adSizes.fluid,
        ],
    },
    im: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.inlineMerchandising,
            adSizes.fluid,
        ],
    },
    'merchandising-high': {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.merchandisingHigh,
            adSizes.fluid,
        ],
    },
    'merchandising-high-lucky': {
        mobile: [adSizes.outOfPage, adSizes.empty, adSizes.fluid],
    },
    merchandising: {
        mobile: [
            adSizes.outOfPage,
            adSizes.empty,
            adSizes.merchandising,
            adSizes.fluid,
        ],
    },
    survey: {
        desktop: [adSizes.outOfPage],
    },
    carrot: {
        mobile: [adSizes.fluid],
    },
    epic: {
        mobile: [adSizes.fluid],
    },
    'mobile-sticky': {
        mobile: [adSizes.mobilesticky],
    },
    'crossword-banner': {
        phablet: [adSizes.outOfPage, adSizes.empty, adSizes.leaderboard],
    },
    'article-end': {
        mobile: [adSizes.fluid],
    },
    exclusion: {
        mobile: [adSizes.empty],
    },
    external: {
        mobile: [adSizes.outOfPage, adSizes.empty, adSizes.fluid, adSizes.mpu],
    },
};
const getAdSize = (size) => adSizes[size];
// Export for testing
export const _ = { createAdSize };
export { adSizes, standardAdSizes, outstreamSizes, getAdSize, slotSizeMappings, createAdSize, };
