import { isString } from '@guardian/libs';
import { once } from 'lodash-es';
import { createAdSize } from 'core/ad-sizes';
import { getCurrentTweakpoint, matchesBreakpoints, } from 'lib/detect/detect-breakpoint';
import { isInAuOrNz, isInCanada, isInRow, isInUk, isInUsOrCa, } from 'lib/utils/geo-utils';
import { pbTestNameMap } from 'lib/utils/url';
const SUFFIX_REGEXPS = {};
const stripSuffix = (s, suffix) => {
    const re = SUFFIX_REGEXPS[suffix] ??
        (SUFFIX_REGEXPS[suffix] = new RegExp(`${suffix}$`));
    return s.replace(re, '');
};
const PREFIX_REGEXPS = {};
const stripPrefix = (s, prefix) => {
    const re = PREFIX_REGEXPS[prefix] ??
        (PREFIX_REGEXPS[prefix] = new RegExp(`^${prefix}`));
    return s.replace(re, '');
};
const contains = (sizes, size) => Boolean(sizes.find((s) => s[0] === size[0] && s[1] === size[1]));
/**
 * Cleans an object for targetting. Removes empty strings and other falsey values.
 * @param o object with falsey values
 * @returns {Record<string, string | string[]>} object with only non-empty strings, or arrays of non-empty strings.
 */
export const removeFalseyValues = (o) => Object.entries(o).reduce((prev, curr) => {
    const [key, val] = curr;
    if (!val)
        return prev;
    if (isString(val)) {
        prev[key] = val;
    }
    if (Array.isArray(val) &&
        val.length > 0 &&
        val.some(Boolean) &&
        val.every(isString)) {
        prev[key] = val.filter(Boolean);
    }
    return prev;
}, {});
export const stripDfpAdPrefixFrom = (s) => stripPrefix(s, 'dfp-ad--');
export const containsMpu = (sizes) => contains(sizes, createAdSize(300, 250));
export const containsDmpu = (sizes) => contains(sizes, createAdSize(300, 600));
export const containsLeaderboard = (sizes) => contains(sizes, createAdSize(728, 90));
export const containsBillboard = (sizes) => contains(sizes, createAdSize(970, 250));
export const containsBillboardNotLeaderboard = (sizes) => containsBillboard(sizes) && !containsLeaderboard(sizes);
export const containsMpuOrDmpu = (sizes) => containsMpu(sizes) || containsDmpu(sizes);
export const containsMobileSticky = (sizes) => contains(sizes, createAdSize(320, 50));
export const containsLeaderboardOrBillboard = (sizes) => containsLeaderboard(sizes) || containsBillboard(sizes);
export const getLargestSize = (sizes) => {
    const reducer = (previous, current) => {
        if (previous[0] >= current[0] && previous[1] >= current[1]) {
            return previous;
        }
        return current;
    };
    return sizes.length > 0 ? sizes.reduce(reducer) : null;
};
export const getBreakpointKey = () => {
    switch (getCurrentTweakpoint()) {
        case 'mobile':
        case 'mobileMedium':
        case 'mobileLandscape':
            return 'M';
        case 'phablet':
        case 'tablet':
            return 'T';
        case 'desktop':
        case 'leftCol':
        case 'wide':
            return 'D';
        default:
            return 'M';
    }
};
export const getRandomIntInclusive = (minimum, maximum) => {
    const min = Math.ceil(minimum);
    const max = Math.floor(maximum);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const shouldIncludeSonobi = () => isInUsOrCa();
export const shouldIncludeOpenx = () => !isInUsOrCa();
export const shouldIncludeTrustX = () => isInUsOrCa();
export const shouldIncludeTripleLift = () => isInUsOrCa();
export const shouldIncludeAdYouLike = (slotSizes) => containsMpu(slotSizes);
// TODO: Check is we want regional restrictions on where we load the ozoneBidAdapter
export const shouldUseOzoneAdaptor = () => !isInCanada() &&
    !isInAuOrNz() &&
    (window.guardian.config.switches.prebidOzone ?? false);
export const shouldIncludeAppNexus = () => isInAuOrNz() ||
    (window.guardian.config.switches.prebidAppnexusUkRow && !isInUsOrCa()) ||
    !!pbTestNameMap().and;
export const shouldIncludeXaxis = () => isInUk();
export const shouldIncludeImproveDigital = () => isInUk() || isInRow();
export const shouldIncludeImproveDigitalSkin = () => !!window.guardian.config.switches.prebidImproveDigitalSkins &&
    window.guardian.config.page.isFront &&
    (isInUk() || isInRow()) &&
    getBreakpointKey() === 'D'; // Desktop only
/**
 * Determine whether to include Criteo as a bidder
 */
export const shouldIncludeCriteo = () => !isInAuOrNz();
/**
 * Determine whether to include Smart as a prebid bidder
 */
export const shouldIncludeSmart = () => isInUk() || isInRow();
export const shouldIncludeMobileSticky = once(() => window.location.hash.includes('#mobile-sticky') ||
    (matchesBreakpoints({
        min: 'mobile',
        max: 'mobileLandscape',
    }) &&
        (isInUsOrCa() || isInAuOrNz()) &&
        window.guardian.config.page.contentType === 'Article' &&
        !window.guardian.config.page.isHosted));
export const stripMobileSuffix = (s) => stripSuffix(stripSuffix(s, '--mobile'), 'Mobile');
export const stripTrailingNumbersAbove1 = (s) => stripSuffix(s, '([2-9]|\\d{2,})');
export const containsWS = (sizes) => contains(sizes, createAdSize(160, 600));
export const shouldIncludeOnlyA9 = window.location.hash.includes('#only-a9');
