import { getCookie } from '@guardian/libs';
import { mergeCalls } from 'lib/utils/async-call-merger';
import { mediator } from 'lib/utils/mediator';
let userFromCookieCache = null;
const cookieName = 'GU_U';
const idApiRoot = window.guardian.config.page.idApiUrl ?? '/ID_API_ROOT_URL_NOT_FOUND';
mediator.emit('module:identity:api:loaded');
const decodeBase64 = (str) => decodeURIComponent(escape(window.atob(str.replace(/-/g, '+').replace(/_/g, '/').replace(/,/g, '='))));
const getUserCookie = () => getCookie({ name: cookieName });
const getUserFromCookie = () => {
    if (userFromCookieCache === null) {
        const cookieData = getUserCookie();
        let userData = null;
        if (cookieData) {
            userData = JSON.parse(decodeBase64(cookieData.split('.')[0]));
        }
        if (userData && cookieData) {
            const displayName = decodeURIComponent(userData[2]);
            userFromCookieCache = {
                id: parseInt(userData[0], 10),
                primaryEmailAddress: userData[1],
                publicFields: {
                    displayName,
                },
                dates: { accountCreatedDate: userData[6] },
                statusFields: {
                    userEmailValidated: Boolean(userData[7]),
                },
                rawResponse: cookieData,
            };
        }
    }
    return userFromCookieCache;
};
const isUserLoggedIn = () => getUserFromCookie() !== null;
const fetchUserIdentifiers = () => {
    const url = `${idApiRoot}/user/me/identifiers`;
    return fetch(url, {
        mode: 'cors',
        credentials: 'include',
    })
        .then((resp) => {
        if (resp.status === 200) {
            return resp.json();
        }
        else {
            console.log('failed to get Identity user identifiers', resp.status);
            return null;
        }
    })
        .catch((e) => {
        console.log('failed to get Identity user identifiers', e);
        return null;
    });
};
const getUserIdentifiersFromApi = mergeCalls((mergingCallback) => {
    if (isUserLoggedIn()) {
        void fetchUserIdentifiers().then((result) => mergingCallback(result));
    }
    else {
        mergingCallback(null);
    }
});
export { getUserIdentifiersFromApi, isUserLoggedIn };
