import fastdom from 'fastdom';
import { dfpEnv } from './dfp-env';
const removeFromDfpEnv = (advert) => {
    const removeAdvert = (adverts) => adverts.filter((_) => _ !== advert);
    dfpEnv.adverts = removeAdvert(dfpEnv.adverts);
    dfpEnv.advertsToLoad = removeAdvert(dfpEnv.advertsToLoad);
    dfpEnv.advertIds = {};
    dfpEnv.adverts.forEach((ad, i) => {
        dfpEnv.advertIds[ad.id] = i;
    });
};
const removeAd = (advert) => {
    const parent = advert.node.parentElement;
    if (parent?.classList.contains('ad-slot-container')) {
        parent.remove();
    }
    else {
        advert.node.remove();
    }
};
const emptyAdvert = (advert) => {
    fastdom.mutate(() => {
        window.googletag.destroySlots([advert.slot]);
        removeAd(advert);
        removeFromDfpEnv(advert);
    });
};
export { emptyAdvert };
