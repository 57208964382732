import { cmp } from '@guardian/consent-management-platform';
import { getCookie, isString } from '@guardian/libs';
import { getLocale } from '../lib/get-locale';
import { getContentTargeting } from './content';
import { getPersonalisedTargeting } from './personalised';
import { getSessionTargeting } from './session';
import { getSharedTargeting } from './shared';
import { getViewportTargeting } from './viewport';
const filterValues = (pageTargets) => {
    const filtered = {};
    for (const key in pageTargets) {
        const value = pageTargets[key];
        if (isString(value)) {
            filtered[key] = value;
        }
        else if (Array.isArray(value) &&
            value.length > 0 &&
            value.every(isString)) {
            filtered[key] = value;
        }
    }
    return filtered;
};
const buildPageTargeting = ({ adFree, clientSideParticipations, consentState, youtube = false, }) => {
    const { page, isDotcomRendering } = window.guardian.config;
    const adFreeTargeting = adFree ? { af: 't' } : {};
    const sharedAdTargeting = page.sharedAdTargeting
        ? getSharedTargeting(page.sharedAdTargeting)
        : {};
    const contentTargeting = getContentTargeting({
        webPublicationDate: page.webPublicationDate,
        eligibleForDCR: page.dcrCouldRender,
        path: `/${page.pageId}`,
        renderingPlatform: isDotcomRendering
            ? 'dotcom-rendering'
            : 'dotcom-platform',
        section: page.section,
        sensitive: page.isSensitive,
        videoLength: page.videoDuration,
        keywords: sharedAdTargeting.k ?? [],
    });
    const getReferrer = () => document.referrer || '';
    const sessionTargeting = getSessionTargeting({
        adTest: getCookie({ name: 'adtest', shouldMemoize: true }),
        countryCode: getLocale(),
        isSignedIn: !!getCookie({ name: 'GU_U' }),
        pageViewId: window.guardian.config.ophan.pageViewId,
        participations: {
            clientSideParticipations,
            serverSideParticipations: window.guardian.config.tests ?? {},
        },
        referrer: getReferrer(),
    });
    const getViewport = () => {
        return {
            width: window.innerWidth || document.body.clientWidth || 0,
            height: window.innerHeight || document.body.clientHeight || 0,
        };
    };
    const viewportTargeting = getViewportTargeting({
        viewPortWidth: getViewport().width,
        cmpBannerWillShow: !cmp.hasInitialised() || cmp.willShowPrivacyMessageSync(),
    });
    const personalisedTargeting = getPersonalisedTargeting({
        state: consentState,
        youtube,
    });
    const pageTargets = {
        ...personalisedTargeting,
        ...sharedAdTargeting,
        ...adFreeTargeting,
        ...contentTargeting,
        ...sessionTargeting,
        ...viewportTargeting,
    };
    // filter !(string | string[]) and empty values
    const pageTargeting = filterValues(pageTargets);
    return pageTargeting;
};
export { buildPageTargeting, filterValues };
